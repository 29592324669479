import { DEFAULT_FEE_COEFFICIENT } from "@daytrip/legacy-config";
import { VehicleType } from "@legacy/domain/VehicleType";
import type { Region } from "@legacy/models/Region";
import type { VehicleTypeCosts } from "@legacy/models/VehicleTypeCosts";
import autobind from "autobind-decorator";
import { action, observable } from "mobx";

import { ModelOperator } from "./ModelOperator";
import type { ModelOperatorOptions } from "./ModelOperatorOptions";

interface RegionOperatorOptions extends ModelOperatorOptions<Region, null, null> {}

@autobind
export class RegionOperator extends ModelOperator<Region, RegionOperatorOptions, null, null, null> {
    @observable
    public isNew: boolean;

    @observable
    public isRegionSaving: boolean;

    @observable
    public geoFencePolygonRaw: string;

    @action
    public ensureVehicleTypeCostsExist(vt: VehicleType) {
        if (this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt) === -1) {
            this.edit((o) => {
                const newCost = {
                    vehicleType: vt,
                    amortisationPrice: 0,
                    consumptionPrice: 0,
                    tripMinimumPrice: 0,
                    tripMinimumPriceUpTo: 0,
                    locationMinimalPrice: 0,
                    feeCoefficient: DEFAULT_FEE_COEFFICIENT,
                } as VehicleTypeCosts;

                o.vehicleTypeCosts.push(newCost);
            });
        }
    }

    @action
    public updateFeeCoefficient(vt: VehicleType, feeCoefficient: number) {
        this.ensureVehicleTypeCostsExist(vt);
        const vehicleTypeCostsIndex = this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt);

        this.edit((o) => {
            o.vehicleTypeCosts[vehicleTypeCostsIndex].feeCoefficient = feeCoefficient;
        });

        this.removeEmptyVehicleTypeCosts();
    }

    @action
    public updateTripMinimumPrice(vt: VehicleType, tripMinimumPrice: number) {
        this.ensureVehicleTypeCostsExist(vt);
        const vehicleTypeCostsIndex = this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt);

        this.edit((o) => {
            o.vehicleTypeCosts[vehicleTypeCostsIndex].tripMinimumPrice = tripMinimumPrice;
        });

        this.removeEmptyVehicleTypeCosts();
    }

    @action
    public updateTripMinimumPriceUpTo(vt: VehicleType, tripMinimumPriceUpTo: number) {
        this.ensureVehicleTypeCostsExist(vt);
        const vehicleTypeCostsIndex = this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt);

        this.edit((o) => {
            o.vehicleTypeCosts[vehicleTypeCostsIndex].tripMinimumPriceUpTo = tripMinimumPriceUpTo;
        });

        this.removeEmptyVehicleTypeCosts();
    }

    @action
    public updateAmortisationPrice(vt: VehicleType, amortisationPrice: number) {
        this.ensureVehicleTypeCostsExist(vt);
        const vehicleTypePriceFeeIndex = this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt);

        this.edit((o) => {
            o.vehicleTypeCosts[vehicleTypePriceFeeIndex].amortisationPrice = amortisationPrice;
        });

        this.removeEmptyVehicleTypeCosts();
    }

    @action
    public updateConsumptionPrice(vt: VehicleType, consumptionPrice: number) {
        this.ensureVehicleTypeCostsExist(vt);
        const vehicleTypePriceFeeIndex = this.m.vehicleTypeCosts.findIndex((v) => v.vehicleType === vt);

        this.edit((o) => {
            o.vehicleTypeCosts[vehicleTypePriceFeeIndex].consumptionPrice = consumptionPrice;
        });

        this.removeEmptyVehicleTypeCosts();
    }

    @action
    public removeEmptyVehicleTypeCosts() {
        this.edit((o) => {
            o.vehicleTypeCosts = o.vehicleTypeCosts.filter(
                (vehicleTypeCosts) =>
                    vehicleTypeCosts.amortisationPrice ||
                    vehicleTypeCosts.consumptionPrice ||
                    vehicleTypeCosts.locationMinimalPrice ||
                    vehicleTypeCosts.tripMinimumPrice ||
                    vehicleTypeCosts.tripMinimumPriceUpTo,
            );
        });
    }
}
